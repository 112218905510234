import revive_payload_client_4sVQNw7RlN from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/code/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/code/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _2_features_rXQAZns1f3 from "/code/plugins/2.features.ts";
import _3_gtm_1Ofx13oYVI from "/code/plugins/3.gtm.ts";
import _4_analytics_client_c7hOxICSji from "/code/plugins/4.analytics.client.ts";
import _5_htlbid_LLEdMD6NsF from "/code/plugins/5.htlbid.ts";
import _6_nativo_encaVpIFws from "/code/plugins/6.nativo.ts";
import chartbeat_client_4XrNwmvvA9 from "/code/plugins/chartbeat.client.ts";
import primevue_TdXjRgL1MA from "/code/plugins/primevue.js";
import sentry_client_GoGQuZo4Et from "/code/plugins/sentry.client.js";
import sfmc_analytics_client_QfyIR3VECB from "/code/plugins/sfmc-analytics.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  _2_features_rXQAZns1f3,
  _3_gtm_1Ofx13oYVI,
  _4_analytics_client_c7hOxICSji,
  _5_htlbid_LLEdMD6NsF,
  _6_nativo_encaVpIFws,
  chartbeat_client_4XrNwmvvA9,
  primevue_TdXjRgL1MA,
  sentry_client_GoGQuZo4Et,
  sfmc_analytics_client_QfyIR3VECB
]