<template>
  <svg
    role="img"
    class="nypr-logo"
    aria-label="NYPR Logo"
    width="75"
    height="31"
    viewBox="0 0 75 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7246_8099)">
      <path
        d="M22.9068 3.71254L20.7009 0.898193H20.0312V4.9693H20.7418V2.07209L23.0126 4.9693H23.6182V0.898193H22.9068V3.71254Z"
        fill="white"
      />
      <path
        d="M25.8764 3.2351H27.9355V2.59585H25.8764V1.53789H28.1988V0.898193H25.1543V4.9693H28.2288V4.32939H25.8764V3.2351Z"
        fill="white"
      />
      <path
        d="M33.1506 3.89832L32.1376 0.898193H31.5249L30.5119 3.89832L29.5276 0.909394H28.7441L30.1819 4.9693H30.8058L31.8192 2.07192L32.8331 4.9693H33.4575L34.8932 0.909394H34.1336L33.1506 3.89832Z"
        fill="white"
      />
      <path
        d="M38.4825 2.70144L37.3015 0.898193H36.4277L38.1039 3.36465V4.9693H38.8516V3.34747L40.5271 0.898193H39.6772L38.4825 2.70144Z"
        fill="white"
      />
      <path
        d="M43.0944 4.33468C42.3162 4.33468 41.7612 3.69837 41.7612 2.92849C41.7612 2.15798 42.3044 1.53345 43.0829 1.53345C43.8615 1.53345 44.4161 2.16913 44.4161 2.9388C44.4161 3.71036 43.872 4.33468 43.0944 4.33468ZM43.0944 0.898193C41.8904 0.898193 41.0391 1.82626 41.0391 2.9388C41.0391 4.05323 41.8787 4.9693 43.0829 4.9693C44.2877 4.9693 45.1384 4.04229 45.1384 2.92849C45.1384 1.81511 44.2978 0.898193 43.0944 0.898193Z"
        fill="white"
      />
      <path
        d="M47.3148 2.91558V1.54833H48.241C48.7118 1.54833 48.9902 1.78128 48.9902 2.22978C48.9902 2.64892 48.701 2.91558 48.2459 2.91558H47.3148ZM49.6383 2.18954C49.6383 1.39194 49.1266 0.898193 48.2921 0.898193H46.6777V4.9693H47.3148V3.54962H48.1113L49.0016 4.9693H49.7523L48.7739 3.42782C49.2815 3.27056 49.6383 2.86382 49.6383 2.18954Z"
        fill="white"
      />
      <path
        d="M53.1189 2.64892L54.804 0.898193H53.9298L52.0035 2.94473V0.898193H51.291V4.9693H52.0035V3.78801L52.6333 3.13679L54.0168 4.9693H54.878L53.1189 2.64892Z"
        fill="white"
      />
      <path
        d="M10.2926 10.2859C9.30554 9.46364 7.91937 9.03833 6.27948 9.03833H0.5625V22.2694H1.27616V9.75661H6.27948C7.80138 9.75661 9.01548 10.149 9.83685 10.8352C10.6573 11.5241 11.1184 12.5029 11.1221 13.8049V13.8409C11.1166 15.2275 10.6048 16.2278 9.73484 16.925C8.86158 17.6189 7.59735 17.9961 6.10172 17.9955H4.02924V22.2694H4.74269V18.7107H6.10172C7.71293 18.7107 9.13764 18.3078 10.1777 17.4857C11.218 16.6654 11.8401 15.409 11.8358 13.8409V13.8049C11.8386 12.3295 11.283 11.1058 10.2926 10.2859Z"
        fill="white"
      />
      <path
        d="M4.39464 15.4415V12.8165L5.74367 12.8165C6.3534 12.8154 6.7954 12.9509 7.06665 13.1609C7.33651 13.3729 7.48609 13.6583 7.49028 14.1195L7.49098 14.1556C7.4719 14.9361 6.95127 15.4115 5.76298 15.4415H4.39464ZM7.5496 15.6302C8.00091 15.2772 8.25261 14.7457 8.24843 14.1556V14.1195C8.25401 13.4957 8.0044 12.9474 7.54192 12.5992C7.08387 12.2489 6.46297 12.0939 5.74367 12.093H3.63672V16.1635H5.76298V16.1641C6.48158 16.1641 7.09899 15.9854 7.5496 15.6302Z"
        fill="white"
      />
      <path
        d="M25.9853 16.5401C25.9827 18.519 25.4374 19.871 24.5095 20.7471C23.5818 21.6244 22.2228 22.0564 20.5105 22.0564C18.7984 22.0558 17.4582 21.6157 16.5474 20.746C15.6376 19.8755 15.1088 18.5469 15.1067 16.6305V9.03833H14.3965L14.3965 16.6305C14.3934 18.6748 14.9769 20.2393 16.0616 21.2738C17.1461 22.3072 18.6851 22.7807 20.5105 22.7783C22.3341 22.7783 23.8899 22.3165 24.9929 21.276C26.0966 20.2364 26.6972 18.6486 26.6946 16.5401V9.04187H25.9853V16.5401Z"
        fill="white"
      />
      <path
        d="M20.5351 19.2161H20.5467C21.281 19.2188 21.9442 18.9907 22.4081 18.494C22.8719 17.9973 23.109 17.2718 23.1086 16.3632V9.04409H22.4207V16.3632C22.4201 17.1582 22.2196 17.6856 21.909 18.0165C21.5984 18.3461 21.152 18.5194 20.5467 18.5207C19.9429 18.5194 19.4999 18.3414 19.1869 17.9966C18.8756 17.6528 18.6716 17.0993 18.6716 16.2756V9.03833H17.9844L17.9844 16.2756C17.9833 17.2102 18.2185 17.9527 18.6803 18.4667C19.1389 18.9771 19.8017 19.2161 20.5351 19.2161Z"
        fill="white"
      />
      <path
        d="M31.5162 17.3951H33.8348C34.3802 17.3933 34.7382 17.5027 34.9293 17.6394C35.1175 17.7803 35.1878 17.922 35.1938 18.1712V18.208C35.186 18.4785 35.105 18.6238 34.9095 18.7656C34.7118 18.9042 34.3594 19.0055 33.8714 19.0041L31.5162 19.0041V17.3951ZM30.793 19.7258H33.8714C34.4513 19.7236 34.941 19.6159 35.3176 19.3612C35.6964 19.1107 35.9239 18.6787 35.917 18.208V18.1712C35.9239 17.7331 35.7224 17.3062 35.3454 17.0486C34.9699 16.7894 34.467 16.6725 33.8348 16.6725H30.793V19.7258Z"
        fill="white"
      />
      <path
        d="M31.518 14.3981V12.8415L33.4427 12.8415C33.926 12.8409 34.2539 12.9403 34.4299 13.0698C34.6023 13.2027 34.6717 13.3345 34.6788 13.5926V13.6296C34.6695 13.914 34.5967 14.0406 34.4112 14.1749C34.2203 14.3064 33.8659 14.3999 33.3704 14.3981H31.518ZM34.8039 14.8029C35.1854 14.5588 35.4126 14.1131 35.4046 13.6296V13.5926C35.411 13.1384 35.207 12.7077 34.8439 12.4558C34.4851 12.2007 34.0129 12.0951 33.4427 12.093H30.793V15.1463H33.3704C33.946 15.1436 34.4272 15.0518 34.8039 14.8029Z"
        fill="white"
      />
      <path
        d="M37.5319 15.2854C39.1087 14.1552 38.9423 12.5321 38.9423 12.5321C38.9423 9.66039 36.0319 8.99085 34.349 9.04088H30.793V9.73764H34.349C37.8423 9.73764 38.2233 11.7586 38.2263 12.5321C38.2263 12.5321 38.3479 14.7021 35.9996 15.3097C35.9996 15.3097 38.7757 16.0069 38.7757 18.3011C38.7757 18.3011 39.1883 21.5599 34.3857 21.5599H30.793V22.2694H34.3857C35.9341 22.2685 37.2033 21.9477 38.0977 21.2834C39.4301 20.2939 39.5134 18.885 39.5035 18.4398C39.5013 18.3502 39.4991 18.2693 39.4973 18.2148C39.4949 17.8268 39.3676 16.2205 37.5319 15.2854Z"
        fill="white"
      />
      <path
        d="M43.2626 9.03833H42.5801V22.2694H51.8037V21.5551H43.2626V9.03833Z"
        fill="white"
      />
      <path
        d="M46.3666 9.03833H45.6543V19.2161H51.8034V18.5023L46.3638 18.5014L46.3666 9.03833Z"
        fill="white"
      />
      <path d="M57.9531 22.2694H58.978V9.03833H57.9531V22.2694Z" fill="white" />
      <path
        d="M54.3652 22.2694H55.3901V9.03833H54.3652V22.2694Z"
        fill="white"
      />
      <path
        d="M71.1837 17.2488L70.9732 17.5068C70.2348 18.3889 69.6106 18.887 68.4327 18.8968C66.9058 18.8929 65.8264 17.6372 65.8124 15.9078L65.8117 15.8727C65.8237 14.2021 66.9077 12.9201 68.4327 12.9194C69.5036 12.9266 70.194 13.4032 70.8802 14.2534L71.4361 13.8075C70.6981 12.8666 69.7488 12.1984 68.4327 12.2061C67.4577 12.2037 66.6086 12.6272 66.0222 13.2978C65.4358 13.9684 65.1011 14.8801 65.1011 15.8727V15.9078C65.1011 16.9254 65.4351 17.8479 66.0222 18.5207C66.6086 19.1954 67.4592 19.6117 68.4327 19.6104C69.7239 19.6196 70.5913 19.0292 71.3083 18.2155L73.3434 19.6716C72.2316 21.0846 70.8147 22.0516 68.3251 22.0619C64.9476 22.0564 62.2548 19.4907 62.2506 15.9431L62.2486 15.9078C62.2558 12.4477 64.8775 9.75982 68.4683 9.7528C70.914 9.76223 72.4109 10.7544 73.4717 12.217L74.049 11.7996C72.8991 10.1842 71.1177 9.03026 68.4683 9.03837C66.4899 9.03815 64.7509 9.79073 63.5136 11.0298C62.2751 12.2677 61.5371 13.992 61.5371 15.9078V15.9431C61.5371 17.9062 62.2936 19.6209 63.5232 20.8389C64.7524 22.0564 66.453 22.7783 68.3251 22.7783H68.3624C71.2103 22.7783 72.9384 21.4849 74.137 19.8042L74.3477 19.5104L71.1837 17.2488Z"
        fill="white"
      />
      <path
        d="M27.3275 28.3617V26.9959H28.2546C28.7255 26.9959 29.0052 27.2267 29.0052 27.6751C29.0052 28.0938 28.7151 28.3617 28.26 28.3617H27.3275ZM29.6521 27.6347C29.6521 26.838 29.1388 26.3433 28.3067 26.3433H26.6914V30.4144H27.3275V28.9964H28.1249L29.0157 30.4144H29.7659L28.7879 28.8733C29.295 28.7167 29.6521 28.3087 29.6521 27.6347Z"
        fill="white"
      />
      <path
        d="M32.668 28.8085L33.3409 27.1929L34.0207 28.8085H32.668ZM33.0317 26.3433L31.3027 30.4144H32.01L32.4149 29.4393H34.2729L34.6717 30.4144H35.4021L33.6729 26.3433H33.0317Z"
        fill="white"
      />
      <path
        d="M37.9057 29.7619H37.1234V26.9949H37.9057C38.7383 26.9949 39.2812 27.5819 39.2812 28.3835C39.2812 29.1864 38.7383 29.7619 37.9057 29.7619ZM37.9057 26.3433H36.4258V30.4144H37.9057C39.1514 30.4144 40.0127 29.5227 40.0127 28.372C40.0127 27.2223 39.1514 26.3433 37.9057 26.3433Z"
        fill="white"
      />
      <path
        d="M41.5527 30.4144H42.5776V26.3433H41.5527V30.4144Z"
        fill="white"
      />
      <path
        d="M45.6581 29.7779C44.8804 29.7779 44.3245 29.1453 44.3245 28.3733C44.3245 27.6029 44.8681 26.9799 45.6459 26.9799C46.4246 26.9799 46.978 27.6147 46.978 28.3845C46.978 29.1562 46.4346 29.7779 45.6581 29.7779ZM45.6581 26.3433C44.4537 26.3433 43.6016 27.271 43.6016 28.3845C43.6016 29.4982 44.4424 30.4144 45.6459 30.4144C46.8488 30.4144 47.7009 29.4866 47.7009 28.3733C47.7009 27.2603 46.8609 26.3433 45.6581 26.3433Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7246_8099">
        <rect
          width="73.7888"
          height="29.5155"
          fill="white"
          transform="translate(0.558594 0.900391)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
